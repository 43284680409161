import { Mixins } from "vue-mixin-decorator";
import { AxiosResponse } from '@/types/axios_types';
import { PostCommentData, DeleteCommentData } from '@/types';

import RequestMixin from '@/mixins/request_mixin'; 

/**
 * コメント投稿service
 */
export default class ActionCommentService extends Mixins<RequestMixin>(RequestMixin) {

  /**
   * コメント投稿
   */
  public postComment(commentData: PostCommentData): Promise<AxiosResponse> {
    return this.postReq(`/api/v1/action_comment`, {'comment': commentData}) as Promise<AxiosResponse>;
  }

  public deleteComment(commentData: DeleteCommentData): Promise<AxiosResponse> {
    return this.deleteReq("/api/v1/action_comment", commentData) as Promise<AxiosResponse>;
  }
}

