
















































































































































































































































































































































































import moment from "moment";
import router from "@/router";

import { Mixins, Prop, Vue } from "vue-property-decorator";
import { Component } from "vue-mixin-decorator";
import { OverlayLoader, DateSearchModal } from "@/components/parts";
import { AchieveLineChart, AchieveHorizontalBarChart } from "@/components/chart";
import ActionReportDetails from "@/components/action_report/ActionReportDetails.vue";
import ActionAnswerStats from "@/components/action_report/ActionAnswerStats.vue";
import ActionFollowService from "@/services/action_follow_service";
import {  default as ActionReportService, DailyAchieveRatesPerActionResponseData, DailyAbilityRatesAbilitySeries, DailyAbilityRatesResponse } from "@/services/action_report_service";
import { default as ActionAnswerService, ActionAnswerDetailResponse } from '@/services/action_answer_service'

import { Code, Messages } from '@/const'
import { ACTION_REPORT_CHART_COLOR, ACHIEVE_REPORT_CHART_COLOR } from "@/const/action_report_chart"

import { ActionAnswerDetailResponseRowData, DateSearchData, CommentData, UserSearchData, ActionFollowData } from "@/types";

import GoodTableHeader from '@/mixins/good_table_mixin'
import ActionReportServiceHelper from '@/mixins/action_report_service_helper'
import ServiceCallHelper from "@/mixins/service_call_helper";

import Filters from '@/utils/filters'
import { SearchParams } from "@/types/good_table_types";

interface MixinInterface extends ActionReportServiceHelper, GoodTableHeader<ActionAnswerDetailResponseRowData> {}

@Component({
  components: {
    OverlayLoader,
    AchieveLineChart,
    AchieveHorizontalBarChart,
    ActionReportDetails,
    ActionAnswerStats,
    DateSearchModal,
  },
  filters: Filters
})
export default class ActionReportContent extends Mixins<MixinInterface>(ServiceCallHelper, GoodTableHeader) {
  // /** ユーザーID */
  userId = this.$route.params.userId;
  /** コメント投稿対象者のユーザーID */

  // アクションリスト状態フラグ
  statusCode = 3;

  /** ユーザー名 */
  userName = "";

  /** action id */
  aid: string | null = null;

  /** 検索条件テキスト */
  filterText = "";

  followData: ActionFollowData | null = null;

  isHoverFollowButon = false;

  /** 入力フィールド */
  fields = new DateSearchData(
    moment()
      .subtract(1, "months")
      .subtract(1, "days")
      .format("YYYY-MM-DD"),
    moment().subtract(1, "days").format("YYYY-MM-DD")
  );

  /** 達成率ラインチャート平均 */
  achieveLineChartAvg = 0;

  chartLabelData: {actionLabel: string; actionText: string}[] = [];

  /** カテゴリごとラインチャート平均 */
  abilityLineChartAvg = 0;

  abilityChartLabelData: {actionLabel: string; actionText: string}[] = [];

  /** カテゴリのチャートデータ */
  abilityHorizontalChartData: any = {}

  /** カテゴリのチャート高さ */
  abilityHorizontalChartHeight = 0;

  /** アクションの達成率チャートの表示・非表示切り替え */
  isShowActionLineChart = true;
  changeShowActionLineChart() {
    this.isShowActionLineChart = !this.isShowActionLineChart;
    if(this.isShowActionLineChart) {
      this.achieveLineChartMounted()
    }
  }

  /** アクションの詳細一覧の表示・非表示切り替え */
  isShowActionDetail = false;

  /** カテゴリごと達成率チャートの表示・非表示切り替え */
  isShowAbilityLineChart = false;
  changeShowAbilityLineChart() {
    this.isShowAbilityLineChart = !this.isShowAbilityLineChart;
    if(this.isShowAbilityLineChart) {
      this.abilityLineChartMounted()
    }
  }

  /** カテゴリの詳細一覧の表示・非表示切り替え */
  isShowAbilityDetail = false;

  /** アクション設定日 */
  latestChangeDate = '';

  /** アクションリストの直近の更新日 */
  latestListChangeDate = '';

  /** アクションリスト変更通知メッセージ表示日 */
  listChangeAnnotationDate = '';

  /** コメント一覧表示用データ */
  listComments: Array<CommentData> = [];

  /** 回答率 */
  answerRate = 0;

  /** リアクション獲得率 */
  reactionRate = 0;

  /** 有効回答率 */
  achieveRate = 0;
  validAnswerRate = 0;

  /** 有効回答の有無 */
  noAnswer = false;

  /** mounted時、データ更新 */
  mounted() {
    if(this.aid) {
      this.getActionReport(this.serverParams);
    } else {
      this.updateFilter();
    }
    // カテゴリ関連のデータを取得・グラフのレンダリング
    this.abilityLineChartMounted();
  }

  created() {
    // aid設定
    this.aid = (this.$route.query.aid as string);
    // フォローデータ取得
    (this.userId
      ? new ActionFollowService().getUserFollowData(this.userId, {
          ar: 1,
          limit: 10,
        })
      : new ActionFollowService().getMyFollowData({ limit: 10 })
    ).then((response) => {
      if (this.commonApiErrorHandler(response)) {
        const data = response.data;
        Vue.$log.debug(this.userId);
        if (data.code == Code.SUCCESS) {
          this.followData = data.result;
        }
      }
    });
  }

  getActionReport(serverParams: SearchParams, isCommentList = false, targetDate = "", targetUserId = 0) {
    this.isLoading = true;
    this.serverParams = serverParams;
    const actionReportDetails = this.$refs.actionReportDetails as ActionReportDetails;

    Vue.$log.debug(this.serverParams);

    // service戻り値処理
    const thenFunc = (response: ActionAnswerDetailResponse) => {
      const data = response.data;
      Vue.$log.debug(this.userId);
      if(this.commonApiErrorHandler(response)) {
        // SUCCESSの場合は値を設定
        if(data.code == Code.SUCCESS) {
          Vue.$log.debug(response.data);
          actionReportDetails.totalRows = response.data.result.totalRows;
          this.userName = response.data.result.userName;
          this.latestListChangeDate = response.data.result.latestListChangeDate;
          // コメント一覧からの投稿の場合はコメントを更新
          if (isCommentList) {
            const currentAction = response.data.result.records.filter(
              (v) =>
                v.answerDate === targetDate && v.userId === Number(targetUserId)
            );
            if (currentAction) {
              actionReportDetails.listComments = currentAction[0].comments;
            }
          }
          if(actionReportDetails.totalRows > 0) {
            actionReportDetails.rows = response.data.result.records;
            // リスト変更メッセージ表示日を設定
            for(const row of actionReportDetails.rows) {
              if(row.answerDate < this.latestListChangeDate) {
                break;
              }
              this.listChangeAnnotationDate = row.answerDate;
            }
            if(actionReportDetails.rows[actionReportDetails.rows.length - 1].answerDate == this.listChangeAnnotationDate) {
              this.listChangeAnnotationDate = ''
            }
          }
          else {
            actionReportDetails.rows = [];
          }
          actionReportDetails.pageLoaded = true;
        }
        else if(data.code == Code.ACTION_LIST_NOT_FOUND) {
          this.setActionListNotFound();
        }
        else if(data.code == Code.ACTION_LIST_NOT_APPROVED) {
          this.setActionListNotApproved();
        }
      }
    }

    // 指定userIdのレポート取得
    new ActionAnswerService().searchActionDetails(this.userId, this.serverParams, this.aid)
      .then(thenFunc)
      .finally(()=> {
        this.isLoading = false;
        this.reportDetailLoaded(this.userName);
      });
  }

  /** 日付検索反映 */
  closeDateSearchModal() {
    Vue.$log.debug("closeDateSearchModal");
    this.aid = null;
    this.updateFilter();
    this.$modal.hide("date-search-modal");
  }

  /** 絞り込み検索テキストの更新 */
  updateFilter() {
    Vue.$log.debug("updateFilter");

    // 日付検索モーダルから値の取得
    const dateSearchModal = this.$refs.dateSearchModal as DateSearchModal;
    if(dateSearchModal) {
      this.filterText = dateSearchModal.searchText();
      // 子コンポーネントで参照されている値の設定
      this.fields = new DateSearchData(
        dateSearchModal.fields.dateFrom,
        dateSearchModal.fields.dateTo
      );
    }

    // 達成率グラフコンポーネントの更新
    if (this.$refs.achieveLineChart) {
      this.achieveLineChartMounted();
    }

    // カテゴリ達成率グラフコンポーネントの更新
    if(this.$refs.abilityLineChart) {
      this.abilityLineChartMounted();
    }

    // アクションごと集計更新
    if (this.$refs.actionAnswerStats) {
      const actionAnswerStats = this.$refs
        .actionAnswerStats as ActionAnswerStats;
      actionAnswerStats.loadStats(this.fields.dateFrom, this.fields.dateTo);
    }
  }

  /** 達成率チャート描画 */
  achieveLineChartMounted() {
    Vue.$log.debug("achieveLineChartMounted");
    Vue.$log.debug(this.fields.dateFrom);
    Vue.$log.debug(this.fields.dateTo);
    const achieveLineChart = this.$refs.achieveLineChart as AchieveLineChart;
    const userSearchData = {
      termFrom: this.fields.dateFrom,
      termTo: this.fields.dateTo,
      userId: this.userId,
    };
    achieveLineChart.search(userSearchData as UserSearchData);
  }

  /** カテゴリ達成率チャート描画 */
  abilityLineChartMounted() {
    const abilityLineChart = this.$refs.abilityLineChart as AchieveLineChart;

    // カテゴリごとのチャートは複数箇所のデータを取得するので、ここでまとめて行う
    const renderChart = (response: DailyAbilityRatesResponse) => {
      abilityLineChart.getMyAbilityAchiveRateAction(response);
      this.abilityHorizontalBarChartMounted(response);
      this.renderTotal(response);
    }
    new ActionReportService().getAbilityAchiveRate(
      this.userId, this.fields.dateFrom, this.fields.dateTo,
    ).then(renderChart);
  }

  /**
   * トータルの要素を描画
   */
  renderTotal(response: DailyAbilityRatesResponse) {
    const data = response.data;
    this.latestChangeDate = data.result.latestChangeDate;
    this.answerRate = data.result.total.answerRate;
    this.reactionRate = data.result.total.reactionRate;
    this.achieveRate = data.result.total.achieveRate;
    this.validAnswerRate = data.result.total.validAnswerRate;
    if(data.result.total.userCount) {
      this.noAnswer = false;
    }
    else {
      this.noAnswer = true;
    }
    Vue.$log.debug(`this.noAnswer=${this.noAnswer}, total=${data.result.total}`)
  }

  /**
   * カテゴリごとのチャート描画
   */
  abilityHorizontalBarChartMounted(response: DailyAbilityRatesResponse) {
    if(this.commonApiErrorHandler(response)) {
      const data = response.data;
      // SUCCESSの場合は値を設定
      if(data.code == Code.SUCCESS) {
        const abilityBar = data.result.abilityBar;
        // グラフの高さを動的に生成
        if (abilityBar.labels.length === 1) {
          this.abilityHorizontalChartHeight = 80;
        } else if (abilityBar.labels.length === 2) {
          this.abilityHorizontalChartHeight = 100;
        } else {
          this.abilityHorizontalChartHeight = abilityBar.labels.length * 40;
        }
        this.abilityHorizontalChartData = {
          labels: abilityBar.labels,
          data: abilityBar.data.map((v: number) => Math.floor(v * 100)),
        }

        const abilityHorizontalBarChart = this.$refs.abilityHorizontalBarChart as AchieveHorizontalBarChart;
        abilityHorizontalBarChart.search(abilityBar);
      // その他のケースは接続エラーに
      } else {
        this.$store.commit('setErrorMessage', Messages.NETWORK_ERROR);
      }
    }
  }

  /**
   * 達成率ラインチャートの描画終了時、平均再描画実行
   */
  achieveLineChartUpdated(data: DailyAchieveRatesPerActionResponseData) {
    Vue.$log.debug('achieveLineChartUpdated');
    Vue.$log.debug(data);
    const info = {
      date: data.date,
      chartData: data.chartData,
    }
    this.achieveLineChartAvg = data.chartDataAvg;
    this.chartLabelData = info.chartData.map(data => ({
      actionLabel: data.actionLabel,
      actionText: data.actionText
    }))
  }

  /**
   * カテゴリごとの達成率ラインチャートの描画終了時、平均再描画実行
   */
  abilityLineChartUpdated(data: DailyAbilityRatesAbilitySeries) {
    Vue.$log.debug('abilityLineChartUpdated');
    Vue.$log.debug(data);
    const info = {
      date: data.date,
      chartData: data.chartData,
    }
    this.abilityLineChartAvg = data.chartDataAvg;
    this.abilityChartLabelData = info.chartData.map((data) => ({
      actionLabel: data.abilityName,
      actionText: data.abilityName
    }))
  }

  /** 条件を絞り込み検索ボタン */
  clickFilterButton() {
    this.$modal.show("date-search-modal");
  }

  /** アクションリスト設定へ遷移 */
  moveActionListSettings() {
    router.push("/action_list/settings");
  }

  /** アクション回答へ遷移 */
  moveActionAnswer() {
    router.push("/action_answer");
  }

  /** ActionReportDetailからユーザー名の通知 */
  reportDetailLoaded(userName: string) {
    this.userName = userName;
    this.statusCode = 3;
  }

  /**  */
  setActionListNotFound() {
    this.statusCode = 1;
  }

  /**  */
  setActionListNotApproved() {
    this.statusCode = 2;
  }

  /** フォローボタン */
  clickFollowButton() {
    if (this.userId) {
      new ActionFollowService()
        .postFollow(this.userId, { ar: 1 })
        .then((response) => {
          if (this.commonApiErrorHandler(response)) {
            const data = response.data;
            Vue.$log.debug(this.userId);
            if (data.code == Code.SUCCESS) {
              this.followData = data.result;
            }
          }
        });
    }
  }

  /** フォロー解除ボタン */
  clickUnFollowButton() {
    if (this.userId) {
      new ActionFollowService()
        .postUnFollow(this.userId, { ar: 1 })
        .then((response) => {
          if (this.commonApiErrorHandler(response)) {
            const data = response.data;
            Vue.$log.debug(this.userId);
            if (data.code == Code.SUCCESS) {
              this.followData = data.result;
            }
          }
        });
    }
  }

  /**
   * グラフの表示・非表示
   */
  toggleChartLine(value: number, actionLabel: string) {
    const achieveLineChart = this.$refs.achieveLineChart as AchieveLineChart;
    achieveLineChart.hideChartLine(value, actionLabel);
  }

  /**
   * 能力別グラフの表示・非表示
   */
  toggleAbilityChartLine(value: number, actionLabel: string) {
    const achieveLineChart = this.$refs.abilityLineChart as AchieveLineChart;
    achieveLineChart.hideChartLine(value, actionLabel);
  }

  /**
   * 凡例の色取得
   */
  getChartLegendColor(actionLabel: string) {
    return ACTION_REPORT_CHART_COLOR.find(color => color.actionLabel === actionLabel)?.color || "#999999"
  }

  /**
   * 凡例の色取得（カテゴリごと）
   */
  getAbilityChartLegendColor(actionLabel: string, index: number) {
    if (actionLabel === '平均') {
      return ACTION_REPORT_CHART_COLOR[0].color;
    } else {
      return ACHIEVE_REPORT_CHART_COLOR[index];
    }
  }
}
