/**
 * アクションリスト行データ
 */
export class ActionListData {
  id = "";
  text = "";
  alpha = "";
}

export interface ActionListRowData {
  actions: Array<ActionListData>;
  dispBasicAbility: string;
}

/**
 * アクションリスト能力別データ（表示用）
 */
export interface ActionListAbilityDispData {
  dispBasicAbility: string;
  abilityName: string;
  sortNum: number;
  actions: Array<AbilityActionData>;
}

/**
 * アクションデータ
 */
export interface AbilityActionData {
  id: number;
  text: string;
  difficultyScore: number;
  required: number;
}

/**
 * アクションリスト一覧データ
 */
export interface ActionListResponseData {
  action: Array<ActionListAbilityDispData>;
  userAction: Array<number>;
  userName: string;
  alLimitBelow: number;
  alLimitAbove: number;
  userComment: string;
  managerComment: string;
  strongWeakPoints: any;
}

/**
 * アクションリストpostデータ
 */
export interface ActionListObject {
  [key: string]: string;
}

/**
 * アクションレポート一覧データ
 */
export interface ActionReportListData {
  userId: number;
  userName: string;
  checkRate: number;
  reactionRate: number;
  yesterdayAchieveRate: number;
  avgAchieveRate: number;
  avgAnswerRate: number;
  approvalDate: string;
  actionCount: number;
  approved: 1 | 2 | 3; // 1:承認, 2:未承認, 3:未申請
  followUser: boolean;
}

export interface ActionshowHiddenCommentUsersData {
  departmentId: number;
  departmentName: string;
  userId: number;
  userName: string;
}

/**
 * アクション回答表示データ
 */
export interface ActionAnswerResponseData {
  action: Array<ActionListAbilityDispData>;
  userAnswer: ActionListObject;
  actionCount: number;
  userComment: string;
  hiddenComment: string;
  managerComment: string;
  showHiddenCommentUsers: Array<ActionshowHiddenCommentUsersData>;
  expireDateDiff: number | null;
}

/**
 * アクション回答詳細表示データ
 */
export interface ActionAnswerDetailResponseRowData {
  date: string;
  actionA: string;
  actionTextA: string;
  actionB: string;
  actionTextB: string;
  actionC: string;
  actionTextC: string;
  actionD: string;
  actionTextD: string;
  actionE: string;
  actionTextE: string;
  actionF: string;
  actionTextF: string;
  actionG: string;
  actionTextG: string;
  actionH: string;
  actionTextH: string;
  actionI: string;
  actionTextI: string;
  actionJ: string;
  actionTextJ: string;
  userComment: string;
  userId: number;
  answerDate: string;
  answerTime: string;
  totalComments: number;
  dailyAnswerId: number;
  hiddenComment: string;
  stamps: Array<StampData>;
  comments: Array<CommentData>;
  hasMyComment: number; // 1. 開いているユーザーがコメントしている / 0. していない
}

export interface StampData {
  dailyAnswerId: string;
  stampId: number;
  path: string;
  entity: string;
  count: number;
  authors: Array<string>;
  myStamp: number;
}

export interface ReplyCommentData {
  commentId: number;
  replyUserId: number;
  author: string;
  authorId: number;
  text: string;
  profileImage: string;
  commentTime: string;
  myComment: number; // 1. ログインユーザーのコメント / 0. NO
}

export interface CommentData {
  commentId: number;
  author: string;
  authorId: number;
  text: string;
  commentTime: string;
  myComment: number; // 1. ログインユーザーのコメント / 0. NO
  replyComment: Array<ReplyCommentData> | undefined;
}

export interface ActionAnswerStatsData {
  indexChar: string;
  text: string;
  achieveRate: number;
  answerCount: number;
}

export interface ActionEncourageData {
  avgAchieveRate: number;
  memoRate: number;
  memoLength: number;
  adminCommentRate: number;
  adminCommentLength: number;
  noCheckDays: number;
  answerCount: number;
  approvalDate: string;
  name: string;
}

export interface PostStampData {
  dailyAnswerId: string;
  answerDate: string;
  stampId: number;
  userId: string;
}

export interface PostCommentStampData {
  dailyAnswerId: string;
  answerDate: string;
  stampId: number;
  userId: string;
  commentId: number | string;
}

export class PostCommentData {
  dailyAnswerId: string;
  text = "";
  replyUserId?: number | string = ""; // 返信対象のユーザーのID
  commentId?: number | string = ""; // 編集対象のコメントID
}

export class DeleteCommentData {
  dailyAnswerId: string;
  commentId: number | string = "";
}

export interface ActionFollowData {
  followed: number;
  follows: Array<ActionFollowUserData>;
  followers: Array<ActionFollowUserData>;
  user: ActionFollowUserData;
  au?: Array<ActionFollowUserData>;
  ru?: Array<ActionFollowUserData>;
}

export interface ActionFollowUserData {
  userId: number;
  userName: string;
  departmentName: string;
  profileImage: string;
  followed: number;
}

export interface ActionTimelineData {
  answerDate: string;
  answerTime: string;
  totalComments: number;
  stamps: Array<StampData>;
  comments: Array<CommentData>;
  hasMyComment: number;
  hasReaction: number;
  userComment: string;
  hiddenComment: string;
  userId: number;
  achieveRate: number;
  avgAchieveRate: number;
  answerRate: number;
  prevAvgAchieveRate: number;
  prevAnswerRate: number;
  prevAchieveRate: number;
  actionA: string;
  actionIdA: number;
  actionB: string;
  actionIdB: number;
  actionC: string;
  actionIdC: number;
  actionD: string;
  actionIdD: number;
  actionE: string;
  actionIdE: number;
  actionF: string;
  actionIdF: number;
  actionG: string;
  actionIdG: number;
  actionH: string;
  actionIdH: number;
  actionI: string;
  actionIdI: number;
  actionJ: string;
  actionIdJ: number;
}

export class ActionTeamboardCount {
  noReactionCount = 0;
  noAnswers: Array<NoAnswerUser> = [];
  lastUpdated: string | null = null;
  lastViewd: string | null = null;
}

export interface ActionNoAnswer {
  actionListNotFound: number;
  actionListNotApproved: number;
  noAnswerDates: Array<NoAnswerUser>;
}

export interface WaitApproveUsers {
  userName: string;
  userId: number;
  requestDate: string;
}

export interface NoAnswerUser {
  userId: number;
  userName: string;
  answerDate: string;
}

export interface AlertInfo {
  userId: number;
  answerDate: string;
  alertType: string;
}

export interface ApproveAlertInfo {
  userId: number;
  requestDate: string;
  alertType: string;
}