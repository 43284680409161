




































































































































































































































































































































































































































































































































































































































































































































































































































































import moment from 'moment';
import { Vue } from "vue-property-decorator";
import { Component, Mixins } from "vue-mixin-decorator";
import { Messages, Code } from "@/const";

import {
  default as UserService,
  UserIdSearchResponse,
} from "@/services/user_service";
import ServiceCallHelper from "@/mixins/service_call_helper";
import {
  ErrorMessage,
  OverlayLoader,
  DayOfWeekCheckbox,
  CycleFreqSelectBox,
  HourMinuteSelectBox,
  HelpLineId,
} from "@/components/parts";
import Filters from '@/utils/filters'

import router from "@/router";

import { UserEditData, UserEditReadOnly, AxiosResponse, VueCalendarDate, FieldErrors } from "@/types";
import { validationMixin } from "vuelidate";
import { UserEditValidator } from "@/validators";

import { PositionOccupationSelect, DepartmentSelect } from "@/mixins";
import Calendar from 'v-calendar/lib/components/calendar.umd'

/** 役職、職種、組織のセレクトボックス値設定 */
interface MixinInterface
  extends ServiceCallHelper,
    PositionOccupationSelect,
    DepartmentSelect {}

import { sameAs, minLength } from "vuelidate/lib/validators";

import UserEditFormSubmit from "./UserEditFormSubmit.vue";
import UserDeleteButton from "./UserDeleteButton.vue";
import UserEditFormWebAuthn from "./UserEditFormWebAuthn.vue";

@Component({
  mixins: [validationMixin],
  components: {
    OverlayLoader,
    ErrorMessage,
    DayOfWeekCheckbox,
    CycleFreqSelectBox,
    HourMinuteSelectBox,
    HelpLineId,
    Calendar,
    UserEditFormSubmit,
    UserDeleteButton,
    UserEditFormWebAuthn,
  },
  validations: {
    fields: new UserEditValidator(),
    loginPassword: {
      sameAsPassword: sameAs("loginPassword2"),
      minLength: minLength(8),
    },
    loginPassword2: {},
  },
  filters: Filters
})
export default class UserEditForm extends Mixins<MixinInterface>(
  ServiceCallHelper,
  PositionOccupationSelect,
  DepartmentSelect
) {
  // ユーザー情報
  fields = new UserEditData();

  // 読み取り専用フィールドの情報
  readonlyFlags = new UserEditReadOnly();

  // 個人設定を適用時のフォーム表示
  showPersonalSetting = false;

  /** root組織を表示するか */
  showRootDepartment = false;

  // 表示対象userId
  userId = "";

  // file upload error message
  fileErrorMessage = "";

  // ファイル名表示
  fileLabel = "";

  // パスワードフィールド
  loginPassword = "";
  loginPassword2 = "";

  // 入社年のselect box年一覧
  hireYearOptions = Array.from(
    { length: new Date().getFullYear() - 1969 },
    (v, k) => k + 1970
  );

  /** カレンダー日付 */
  days: Array<VueCalendarDate> = [];
  get dates() {
    return this.days.map(day => day.date);
  }
  get dateIds() {
    return this.days.map(day => day.id);
  }
  get calendarAttributes() {
    return this.dates.map(date => ({
      key: moment(date, 'YYYY-mm-dd').toString(),
      highlight: true,
      dates: date,
    }));    
  }

  /**
   * mount時、ユーザー情報を取得しフォームに設定する
   */
  mounted() {
    if (this.$route.params.id) {
      this.userId = this.$route.params.id;
    } else {
      this.userId = "";
    }
    this.updateFields();
  }

  /**
   * フィールド情報の更新
   */
  updateFields() {
    this.showLoading();

    const thenFunc = (response: UserIdSearchResponse) => {
      if (this.commonApiErrorHandler(response)) {
        const data = response.data;
        // 権限チェック
        if (data.code == Code.INVALID_USER_ROLE) {
          this.$swal("スーパーユーザーの情報は変更できません。", "", "warning");
          router.push("/user");
        }
        // SUCCESS時は成功通知
        else if (data.code == Code.SUCCESS) {
          Vue.$log.debug(data.result);
          this.fields = data.result.user;
          this.fields.loginPassword = "";
          this.fields.loginPassword2 = "";
          this.changeUsePersonalSetting(this.fields.usePersonalSetting);

          // 営業日設定
          for(const day of data.result.businessDays) {
            this.days.push({id: day, date: moment(day).toDate()});
          }

          // 権限に応じてreadonlyの解除
          if (this.$store.getters.userRole == "2") {
            this.readonlyFlags.setMentor();
          } else if (this.$store.getters.userRole == "1") {
            this.readonlyFlags.setSuper();
          }
          this.pageLoaded = true;
        } else {
          this.$swal(Messages.NETWORK_ERROR, "", "error");
        }
      }
    };

    // userIdが設定されている場合はuser api呼び出し
    if (this.userId) {
      new UserService()
        .getUser(this.userId)
        .then(thenFunc)
        .finally(() => {
          this.hideLoading();
        });
    }
    // userIdが設定されていない場合はmy_user api呼び出し
    else {
      new UserService()
        .getMyUser()
        .then(thenFunc)
        .finally(() => {
          this.hideLoading();
        });
    }
  }

  clickHelpLineId() {
    this.$modal.show("help-line-id-modal");
  }

  /**
   * 組織設定を適用を選択時に配信頻度ののフォームの表示/非表示を切り替える
   */
  changeUsePersonalSetting(value: string | number) {
    this.showPersonalSetting = value.toString() == "1";
  }

  /**
   * 初回詳細表示時に職種の設定
   */
  firstDetailToggle = true;
  clickDetailToggle() {
    if (this.firstDetailToggle) {
      this.changeOccupation();
      this.firstDetailToggle = false;
    }
  }

  /*
   * ファイル変更時
   */
  changeUploadProfileFile(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    if (!files) {
      Vue.$log.debug("not files");
    } else if (!files.length) {
      this.fileErrorMessage = Messages.FILE_REQUIRE_ERROR;
    } else {
      Vue.$log.debug(files[0]);
      this.fileLabel = files[0].name;
      this.fileErrorMessage = "";
    }
  }

  /**
   * profile imageのアップロード
   */
  clickUploadProfileImage() {
    const form = document.getElementById("form") as HTMLFormElement;
    if (form.uploadFile.files.length == 0) {
      this.$swal(Messages.FILE_REQUIRE_ERROR, "", "warning");
      return;
    }
    const formData = new FormData(form);
    this.showLoading();

    // アップロード
    (this.userId
      ? new UserService().uploadProfileImage(this.userId, formData)
      : new UserService().uploadMyProfileImage(formData)
    )
      .then((response) => {
        if (this.commonApiErrorHandler(response, false)) {
          const data = response.data;
          if (data.code == Code.SUCCESS) {
            this.fileLabel = "";
            this.fields.profileImage = data.result.profileImage;
          } else if (data.code == Code.VALIDATION_ERROR) {
            this.$swal(data.message, "", "warning");
          } else {
            this.$swal(Messages.NETWORK_ERROR, "", "warning");
          }
        }
      })
      .finally(() => {
        this.hideLoading();
      });
  }

  /**
   * profile imageの削除
   */
  clickDeleteProfileImage() {
    // 削除
    this.$swal({
      title: "プロフィール画像を削除します",
      text: "よろしいですか？",
      confirmButtonColor: "#5EB0DE",
      cancelButtonColor: "#FC7067",
      showCancelButton: true,
    }).then((isConfirm) => {
      if (isConfirm.isConfirmed) {
        this.showLoading();
        (this.userId
          ? new UserService().deleteProfileImage(this.userId)
          : new UserService().deleteMyProfileImage()
        )
          .then((response) => {
            if (this.commonApiErrorHandler(response)) {
              const data = response.data;
              if (data.code == Code.SUCCESS) {
                this.fileLabel = "";
                this.fields.profileImage = data.result.profileImage;
              } else if (data.code == Code.VALIDATION_ERROR) {
                this.$swal(data.message, "", "warning");
              } else {
                this.$swal(Messages.NETWORK_ERROR, "", "warning");
              }
            }
          })
          .finally(() => {
            this.hideLoading();
          });
      }
    });
  }

  /** カレンダー日付クリック */
  onDayClick(day: VueCalendarDate) {
    // 選択された日付を追加
    const idx = this.days.findIndex(d => d.id === day.id);
    if (idx >= 0) {
      this.days.splice(idx, 1);
    } else {
      this.days.push({
        id: day.id,
        date: day.date,
      });
    }
    Vue.$log.debug(this.days);
  }

  clickSetUserAction() {
    if(this.userId) {
      router.push(`/action_list/manager_settings/${this.userId}`);
    }
  }
}
