

import moment from "moment";
import Chart from "chart.js";
import { Prop, Vue } from 'vue-property-decorator';
import { Component, Mixins } from 'vue-mixin-decorator';
import { Line } from "vue-chartjs";
import { default as ActionReportService, DailyAchieveRatesResponse, DailyAchieveRatesPerActionResponse, DailyAbilityRatesResponse } from "@/services/action_report_service";
import { UserSearchData } from "@/types";
import ServiceCallHelper from '@/mixins/service_call_helper'
import { ACTION_REPORT_CHART_COLOR, ACHIEVE_REPORT_CHART_COLOR } from "@/const/action_report_chart"

interface MixinInterface extends ServiceCallHelper, Line {}

@Component
export default class AchieveRateChart extends Mixins<MixinInterface>(ServiceCallHelper, Line) {

  @Prop({default: false})
  myChart: boolean;

  @Prop({default: false})
  myAchiveChart: boolean; // アクションの達成率

@Prop({default: 'all'})
  type: 'all' | 'action';

  chartData: Chart.ChartData = {};

  abilityChartData: Chart.ChartData = {};

  chartOptionsAction: Chart.ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    scales: {
      yAxes: [{
        ticks: {
          min: 0.0,
          max: 100.0,
          stepSize: 25.0,
          callback: function(tick) {
            return tick.toString() + '%';
          }
        }
      }] 
    },
    layout: {
      padding: {
        left: 20,
        right: 20,
        top: 20,
        bottom: 20
      }
    },
    plugins: {
      datalabels: {
        display: false
      }
    },
  };

  /** mount済み通知 */
  mounted() {
    this.$emit('onMounted');
  }

  /** グラフ描画用データ検索 */
  search(searchParams: UserSearchData) {
    Vue.$log.debug(searchParams);
    const thisSearchParams = Object.assign({}, searchParams)

    if(!searchParams.termFrom && !searchParams.termTo) {
      thisSearchParams.termFrom = moment().subtract(6, 'months').format('YYYY-MM-DD');
      thisSearchParams.termTo = moment().format('YYYY-MM-DD');
    }
    Vue.$log.debug(thisSearchParams);

    const getAchieveRatePerAction = (response: DailyAchieveRatesPerActionResponse) => {
      if(this.commonApiErrorHandler(response)) {
        const data = response.data;
        const info = {
          date: data.result.date,
          chartData: data.result.chartData,
          chartDataAvg: Math.floor(data.result.chartDataAvg * 100)
        }

        const datasets = info.chartData.map(data => {
          const targetColor = ACTION_REPORT_CHART_COLOR.find(color => color.actionLabel === data.actionLabel)?.color || "#999999"

          return {
            type: "line",
            label: data.actionLabel,
            borderColor: data.actionLabel === "平均" ? "transparent" : targetColor,
            fill: false,
            backgroundColor: targetColor,
            lineTension: 0.5,
            data: data.value.map(v => Math.floor(v * 100))
          }
        })

        this.chartData = {
          labels: info.date,
          datasets: datasets,
        };
        this.renderChart(this.chartData, this.chartOptionsAction);
        this.$emit('onUpdated', data.result);
      }
    };

    /** ダッシュボードのグラフ設定取得用 */
    const getChartOptionsDashboard = (dataArray: (number | undefined)[]): Chart.ChartOptions => {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: "top",
          align: "center",
          labels: {
            usePointStyle: true,
          },
        },
        elements: {
          point:{
            radius: 5
          }
        },
        scales: {
          xAxes: [
            {
              ticks: {
                stepSize: 14,
                maxTicksLimit: dataArray.length / 14,
              }
            }
          ],
          yAxes: [{
            ticks: {
              min: 0.0,
              max: 100.0,
              stepSize: 25.0,
              callback: function(tick) {
                return tick.toString() + '%';
              }
            }
          }] 
        },
        layout: {
          padding: {
            left: 20,
            right: 20,
            top: 20,
            bottom: 20
          }
        },
        plugins: {
          datalabels: {
            display: false
          }
        }
      }
    }

    // 日付ラベルを取得
    const getDateLabels = (response: DailyAchieveRatesResponse) => {
      const data = response.data;
      const firstDate = data.result.chartData[0].date;
      const lastDate = data.result.chartData[ data.result.chartData.length - 1].date;
      const dateArray = [moment(firstDate).format('YYYY-MM-DD')];
      let currentDate = moment(data.result.chartData[0].date);

      // 特定の日付まで、日付の値を配列に入れながらループ
      const getDateArrayReccursive = () => {
        const newDate = currentDate.add(1, 'days');
        currentDate = newDate;
        dateArray.push(newDate.format('YYYY-MM-DD'))

        if (currentDate >= moment(lastDate)) {
          return;
        } else {
          getDateArrayReccursive();
        }
      }
      getDateArrayReccursive();

      return dateArray;
    }

    const getAchieveRate = (response: DailyAchieveRatesResponse) => {
      if(this.commonApiErrorHandler(response)) {
        const data = response.data;
        const dateArray = getDateLabels(response);
        const dataArray: (number | undefined)[] = dateArray.map(date => {
          const isExistDate = data.result.chartData.find(chartDate => chartDate.date === date);
          if (isExistDate) {
            return isExistDate.value * 100
          }
        })

        this.chartData = {
          labels: dateArray.map(date => moment(date).format('DD.MMM')),
          datasets: [{
            type: "line",
            label: "達成率",
            borderColor: "#5EB0DE",
            fill: "origin",
            backgroundColor: "rgba(94, 176, 222, 0.1)",
            lineTension: 0,
            data: dataArray,
            spanGaps: true, // undefinedの際には線を埋めるようにする
          }],
        };
        this.renderChart(this.chartData, getChartOptionsDashboard(dataArray));
        this.$emit('onUpdated', data.result);
      }
    };

    if (this.type === 'action') {
      if(this.myChart) {
        new ActionReportService().getMyDailyAchieveRate(thisSearchParams).then(getAchieveRatePerAction);
      }
      else {
        new ActionReportService().getDailyAchieveRatePerAction(thisSearchParams).then(getAchieveRatePerAction);
      }
    } else {
      new ActionReportService().getDailyAchieveRate(thisSearchParams).then(getAchieveRate);
    }
  }

  /** カテゴリごとの達成率描画 */
  getMyAbilityAchiveRateAction = (response: DailyAbilityRatesResponse) => {      
    if(this.commonApiErrorHandler(response)) {
      const data = response.data;
      const abilitySeries = data.result.abilitySeries;
      const chart = {
        date: abilitySeries.date,
        chartData: abilitySeries.chartData,
        chartDataAvg: Math.floor(abilitySeries.chartDataAvg * 100)
      }
      const datasets = chart.chartData.map((data, index) => {
        // 平均の線色は強制的に赤に
        const targetColor = data.abilityName === "平均" ? ACTION_REPORT_CHART_COLOR[0].color : ACHIEVE_REPORT_CHART_COLOR[index];

        return {
          type: "line",
          label: data.abilityName,
          borderColor: data.abilityName === "平均" ? "transparent" : targetColor,
          fill: false,
          backgroundColor: targetColor,
          lineTension: 0.5,
          data: data.value.map(v => Math.floor(v * 100))
        }
      })

      this.abilityChartData = {
        labels: chart.date,
        datasets: datasets,
      };
      this.renderChart(this.abilityChartData, this.chartOptionsAction);
      this.$emit('onUpdated', abilitySeries);
    }
  }

  /** データセットの表示・非表示切り替え */
  hideChartLine(value: number, actionLabel: string) {
    const targetColor = ACTION_REPORT_CHART_COLOR.find(color => color.actionLabel === actionLabel)?.color || "#999999"

    if (this.$data._chart.data.datasets[value].hidden) {
      this.$data._chart.data.datasets[value].hidden = false
    } else {
      this.$data._chart.data.datasets[value].hidden = true
    }
    this.$data._chart.update()
  }
}
