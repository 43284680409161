





























































































































































































































































































































































































import { Prop, Vue } from "vue-property-decorator";
import { Component, Mixins } from "vue-mixin-decorator";
import {
  CommentData,
  StampData,
  PostCommentData,
  PostCommentStampData,
  ActionTimelineData,
} from "@/types";
import StampButton from "@/components/action_report/StampButton.vue";
import CommentUpdateModal from "@/components/action_report/CommentUpdateModal.vue";
import StampModal from "@/components/action_report/StampModal.vue";
import AutoUrlLink from "./AutoUrlLink.vue";
import StampService from "@/services/stamp_service";
import { default as ActionAnswerService } from '@/services/action_answer_service'
import ActionCommentService from "@/services/action_comment_service";
import { Code, Messages } from "@/const";
import ActionReportServiceHelper from "@/mixins/action_report_service_helper";

import { OverlayLoader } from '@/components/parts'
import Filters from '@/utils/filters'
import GoodTableHeader from "@/mixins/good_table_mixin";

interface MixinInterface
  extends ActionReportServiceHelper,
    GoodTableHeader<ActionTimelineData> {}

@Component({
  components: {
    CommentUpdateModal,
    StampButton,
    StampModal,
    OverlayLoader,
    AutoUrlLink,
  },
  filters: Filters,
})
export default class CommentListModal extends Mixins<MixinInterface>(
  ActionReportServiceHelper
) {
  /** コメント投稿対象者のユーザーID */
  @Prop()
  comments: Array<CommentData>;

  @Prop()
  memo: { memo: string; date: string };

  @Prop()
  answerTime: string;

  /** 閲覧ユーザーのコメントが存在するかの判定 */
  @Prop()
  inMyComment: boolean;

  /** コメント承認画面か否か */
  @Prop({default: false})
  isCommentConfirm: boolean;

  /** ナイショ話閲覧可能ユーザー名 */
  @Prop()
  hasHiddenComment: boolean;

  /** ログインユーザー名 */
  loginUserRole = this.$store.getters.userInfo.userRole;

  userId = "";

  answerDate = "";

  dailyAnswerId = "";

  /** スタンプ表示情報 */
  stamps: any = [];

  /** モーダルクリック時のcommentId保存 */
  actionCommentId: number | string = "";

  scrollHeights: number[] = [];

  inputComment = "";

  replyInputComment = "";

  /** テキストエリアのデフォルトの高さ */
  defaultTextareaHeight = 94;

  textAreaHeight: number = this.defaultTextareaHeight;

  /** 返信機能 */
  replyUser = "";
  replyUserId = 0;
  replyProfileImage = "";

  /** 編集機能 */
  currentEditCommentId = 0;
  editComment = "";
  editDate = "";
  editReplyUserId = 0;

  processing = false;

  /** スタンプ表示更新 */
  refreshStampDisp(dailyAnswerId: string, userId: string, answerDate: string) {
    Vue.$log.debug("refreshStampDisp");
    this.dailyAnswerId = dailyAnswerId;
    this.userId = userId;
    this.answerDate = answerDate;
    new StampService()
      .getCommentStamp(this.dailyAnswerId)
      .then((response) => {
        Vue.$log.debug(response.data);
        const data = response.data;
        if (data && data.result) {
          this.stamps = data.result;
        }
      });
  }

  /**
   * スタンプ選択モーダル表示
   */
  clickShowStampModal(actionCommentId: number | string) {
    Vue.$log.debug("clickShowStampModal");
    this.actionCommentId = actionCommentId;
    Vue.$log.debug(this.actionCommentId);
    Vue.$log.debug(actionCommentId);
    this.$modal.show("comment-stamp-modal");
  }

  /** 閉じるイベント */
  clickCloseButton() {
    this.$emit("close");
  }

  closeStampModal() {
    Vue.$log.debug("closeStampModal");
  }

  successStampModal() {
    Vue.$log.debug("successStampModal");
  }

  /**
   * モーダルのスタンプクリック
   */
  clickModalStamp(stamp: StampData) {
    Vue.$log.debug(stamp.stampId);
    // stamp登録
    const stampData = {
      dailyAnswerId: this.dailyAnswerId,
      answerDate: this.answerDate,
      commentId: this.actionCommentId,
      stampId: stamp.stampId,
      userId: this.userId,
    };
    Vue.$log.debug("stampData");
    Vue.$log.debug(stampData);
    new StampService().postCommentStamp(stampData).then((response) => {
      if (this.commonApiErrorHandler(response)) {
        Vue.$log.debug(response.data);
        this.refreshStampDisp(this.dailyAnswerId, this.userId, this.answerDate);
      }
    });
    this.$modal.hide("comment-stamp-modal");
  }

  /**
   * 表示スタンプクリック
   */
  clickStampImage(stamp: StampData, answerDate: string, rowIdx: number) {
    Vue.$log.debug("click comment stamp image");
    Vue.$log.debug(rowIdx);
    // API用パラメータ
    const postData = {
      dailyAnswerId: this.dailyAnswerId,
      answerDate: answerDate,
      stampId: stamp.stampId,
      commentId: rowIdx,
      userId: this.userId,
    } as PostCommentStampData;

    // 自スタンプがある場合は削除
    if (stamp.myStamp) {
      new StampService().deleteCommentStamp(postData).then((response) => {
        if (this.commonApiErrorHandler(response)) {
          Vue.$log.debug("deleteStamp");
          this.refreshStampDisp(this.dailyAnswerId, this.userId, this.answerDate);
        }
      });
    }
    // 自スタンプがない場合は追加
    else {
      new StampService().postCommentStamp(postData).then((response) => {
        if (this.commonApiErrorHandler(response)) {
          this.refreshStampDisp(this.dailyAnswerId, this.userId, this.answerDate);
        }
      });
    }
  }

  /** 返信ユーザーを取得 */
  clickReply(
    replyUser: string,
    replyUserId: number,
    replyProfileImage: string
  ) {
    if(this.replyUserId !== replyUserId) {
      this.clearReplyInputComment();
    }
    this.replyUser = replyUser;
    this.replyUserId = replyUserId;
    this.replyProfileImage = replyProfileImage;
  }

  /** 返信クリア */
  clearReplyUser() {
    this.replyUser = "";
    this.replyProfileImage = "";
    if(this.replyInputComment.length !== 0) {
      return
    } else {
      this.replyUserId = 0;
    }
  }

  /** コメント編集 */
  clickEdit(comment: string, date: string, commentId: number, replyUserId = 0) {
    this.editReplyUserId = 0;
    this.editComment = comment;
    this.editDate = date;
    this.currentEditCommentId = commentId;
    if(replyUserId) {
      this.editReplyUserId = replyUserId;
    }

    this.$modal.show("comment-update-modal");
  }

  /** stampデータのclass出し分け */
  stampClass(stamp: StampData) {
    if (stamp.myStamp) {
      return "btn  actionreport--stamp actionreport--stamp-active";
    }
    return "btn  actionreport--stamp";
  }

  /** 入力時、テキストエリアの高さを動的に変更 */
  inputTextarea(e: Event) {
    this.textAreaHeight = (e.target as HTMLInputElement).scrollHeight;
  }

  /** コメント送信 */
  clickCommentButton() {
    if (!this.inputComment || this.processing) return;
    this.processing = true;
    const postData = {
      // answerDate: this.answerDate,
      // userId: this.userId,
      dailyAnswerId: this.dailyAnswerId,
      text: this.inputComment,
    } as PostCommentData;
    // ナイショ話回答のコメントの場合は、ポップアップを表示
    if (this.hasHiddenComment && this.loginUserRole != -1 && this.loginUserRole != 3) {
      this.$swal({
        title: "コメントは、ナイショ話が見えない人も確認できる場合があります。コメントをして問題ありませんか。",
        confirmButtonText: "コメントする",
        cancelButtonText: "戻る",
        confirmButtonColor: "#5EB0DE",
        cancelButtonColor: "#FC7067",
        showCancelButton: true,
      }).then((isConfirm) => {
        if (isConfirm.isConfirmed) {
          this.postCommentData(postData);
        } else {
          this.processing = false;
        }
      });
    } else {
      this.postCommentData(postData);
    }
  }

  /** コメント返信 */
  clickReplyButton() {
    if (!this.replyInputComment || this.processing) return;
    this.processing = true;
    const postData = {
      dailyAnswerId: this.dailyAnswerId,
      text: this.replyInputComment,
      replyUserId: this.replyUserId,
    } as PostCommentData;
    this.postCommentData(postData);
  }

  // コメント投稿API呼び出し処理
  postCommentData(postData: PostCommentData) {
    this.showLoading();
    new ActionCommentService()
        .postComment(postData)
        .then((response) => {
          if (this.commonApiErrorHandler(response)) {
            const data = response.data;
            // SUCCESSの場合は成功通知
            if (data.code == Code.SUCCESS) {
              Vue.$log.debug(data.result.action);
              // if (this.$v.fields.text) {
              //   this.$v.fields.text.$reset();
              // }
              if(postData.replyUserId) {
                this.$emit("success", {
                  date: this.memo.date,
                  userId: this.userId,
                  replyUserId: postData.replyUserId,
                });
                this.clearReplyInputComment();
              } else {
                this.$emit("success", {
                  date: this.memo.date,
                  userId: this.userId,
                });
                this.clearInputComment();
              }
            }
            // その他のケースはエラー通知
            else {
              this.$swal(Messages.NETWORK_ERROR, "", "error");
            }
          }
        })
        .finally(() => {
          this.clearReplyUser();
          this.textAreaHeight = this.defaultTextareaHeight;
          this.processing = false;
          this.hideLoading();
        });
  }

  /** コメント削除 */
  clickDelete(commentId: number, replyUserId = 0) {
    const postData: {
      commentId: number;
      dailyAnswerId: string;
    } = {
      commentId: commentId,
      dailyAnswerId: this.dailyAnswerId,
    };

    this.$swal({
      title: "コメントを削除します",
      text: "削除された回答は復元することができません。よろしいですか？",
      confirmButtonColor: "#5EB0DE",
      cancelButtonColor: "#FC7067",
      showCancelButton: true,
    }).then((isConfirm) => {
      if (isConfirm.isConfirmed) {
        new ActionCommentService()
        .deleteComment(postData)
        .then((response) => {
          if(this.commonApiErrorHandler(response)) {
            const data = response.data;
            Vue.$log.debug(response.data);
            // SUCCESSの場合は成功通知
            if(data.code == Code.SUCCESS) {
                this.$emit("deleteSuccess", {
                  date: this.memo.date,
                  userId: this.userId,
                  replyUserId: replyUserId,
                });
                this.clearReplyInputComment();
                this.clearInputComment();
            }
            // その他のケースはエラー通知
            else {
              this.$swal(Messages.NETWORK_ERROR, "", "error");
            }
          }
        }).finally(() => {
          this.hideLoading();
        });
      }
    });
  }

// コメント承認
  clickCommentConfirm(commentId: number, text: string) {
    new ActionAnswerService()
    .PostActionPendingComment(commentId, text)
    .then((response) => {
      if(this.commonApiErrorHandler(response)) {
        const data = response.data;
        Vue.$log.debug(response.data);
        // SUCCESSの場合は成功通知
        if(data.code == Code.SUCCESS) {
          this.$emit("commentConfirm");
        }
        // その他のケースはエラー通知
        else {
          this.$swal(Messages.NETWORK_ERROR, "", "error");
        }
      }
    }).finally();
  }

  modalClosed() {
    this.scrollHeights = [];
  }

  commentUpdateSuccess(text: string, commentId: number, replyUserId: number) {
    this.$emit("commentUpdate", text, commentId, replyUserId);
    this.$modal.hide('comment-update-modal');
  }

  clearInputComment() {
    this.inputComment = "";
  }

  clearReplyInputComment() {
    this.replyInputComment = "";
  }
}
