

import Chart from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.plugins.unregister(ChartDataLabels);

import { Prop, Vue } from 'vue-property-decorator';
import { Component, Mixins } from 'vue-mixin-decorator';
import { Scatter } from "vue-chartjs";
import { DashboardDailyRates } from '@/types'

import ServiceCallHelper from '@/mixins/service_call_helper'

interface MixinInterface extends ServiceCallHelper, Scatter {}

const imgDomain = process.env.VUE_APP_IMAGE_BASE_URL;

const imgPixelSize = 24;
const imgPointSize = 12;

Chart.plugins.register({
  afterUpdate: (chart: Chart) => {
    if(chart.canvas && chart.canvas.id == 'scatter-chart') {
      // eslint-disable-next-line 
      chart.getDatasetMeta(0).data.forEach((d: any, i: number) => {
        const dataSet = (chart.config.data as any).datasets[0].data[i];
        const img = new Image(imgPixelSize, imgPixelSize);
        if(dataSet.pointStyle) {
          img.src = `${imgDomain}/profile/${dataSet.pointStyle}`;
        }
        else {
          img.src = '/images/chart/point_high.png';
        }
        d._model.pointStyle = img;
      });
      // eslint-disable-next-line 
      chart.getDatasetMeta(1).data.forEach((d: any, i: number) => {
        const dataSet = (chart.config.data as any).datasets[1].data[i];
        const img = new Image(imgPixelSize, imgPixelSize);
        if(dataSet.pointStyle) {
          img.src = `${imgDomain}/profile/${dataSet.pointStyle}`;
        }
        else {
          img.src = '/images/chart/point_middle.png';
        }
        d._model.pointStyle = img;
      });
      // eslint-disable-next-line 
      chart.getDatasetMeta(2).data.forEach((d: any, i: number) => {
        const dataSet = (chart.config.data as any).datasets[2].data[i];
        const img = new Image(imgPixelSize, imgPixelSize);
        if(dataSet.pointStyle) {
          img.src = `${imgDomain}/profile/${dataSet.pointStyle}`;
        }
        else {
          img.src = '/images/chart/point_low.png';
        }
        d._model.pointStyle = img;
      });
    }
  }
});


@Component
export default class AchieveScatterChart extends Mixins<MixinInterface>(ServiceCallHelper, Scatter) {

  @Prop()
  reportData: DashboardDailyRates;

  mounted() {
    this.updateChart(this.reportData);
  }

  /** グラフ再描画 */
  updateChart(reportData: DashboardDailyRates) {
    const chartOptions: Chart.ChartOptions = {
      legend: {
        display: false,
      },
      layout: {
        padding: {
          top: 20
        }
      },
      scales: {
        xAxes: [{
          display: true,
          ticks: {
            beginAtZero: true,
            suggestedMax: this._getXMax(reportData) + 1,
            stepSize: 1,
          }
        }],
        yAxes: [{
          display: true,
          ticks: {
            beginAtZero: true,
            suggestedMax: 100,
            stepSize: 20,
          }
        }],
      },
      tooltips: {
        enabled: true,
        callbacks: {
          label: (tooltipItem: Chart.ChartTooltipItem) => {
            Vue.$log.debug('tooltipCallback');
            Vue.$log.debug(tooltipItem);
            const idx = tooltipItem.index || 0;
            if(tooltipItem.datasetIndex == 0) {
              const userData = reportData.highUsers[idx];
              return [`${userData.departmentName} ${userData.userName} 達成率 ${Math.round(userData.y * 1000) / 10}%`];
            }
            else if(tooltipItem.datasetIndex == 1) {
              const userData = reportData.middleUsers[idx];
              return [`${userData.departmentName} ${userData.userName} 達成率 ${Math.round(userData.y * 1000) / 10}%`];
            }
            else {
              const userData = reportData.lowUsers[idx];
              return [`${userData.departmentName} ${userData.userName} 達成率 ${Math.round(userData.y * 1000) / 10}%`];
            }
          }
        }
      },
      plugins: {
        customOption: {'label': 'customOption'}
      },
      responsive: true,
      maintainAspectRatio: false,
    };

    const datasets: Chart.ChartDataSets[] = [
      {
        data: reportData.highUsers.map(row => ({
          'x': row.x, 'y': row.y*100, 'pointStyle': row.profileImage
        })),
        pointRadius: imgPointSize,
        pointHoverRadius: imgPointSize,
      },
      {
        data: reportData.middleUsers.map(row => ({
          'x': row.x, 'y': row.y*100, 'pointStyle': row.profileImage
        })),
        pointRadius: imgPointSize,
        pointHoverRadius: imgPointSize,
      },
      {
        data: reportData.lowUsers.map(row => ({
          'x': row.x, 'y': row.y*100, 'pointStyle': row.profileImage
        })),
        pointRadius: imgPointSize,
        pointHoverRadius: imgPointSize,
      },
    ]

    Vue.$log.debug(datasets);

    const chartData: Chart.ChartData = {
      datasets: datasets, 
    }

    this.renderChart(chartData, chartOptions);
    Vue.$log.debug('draw scatter chart');
  }

  /**
   * X軸の最大値を取得
   */
  _getXMax(reportData: DashboardDailyRates) {
    return Math.max(
      Math.max(...reportData.highUsers.map(row => row.x)),
      Math.max(...reportData.middleUsers.map(row => row.x)),
      Math.max(...reportData.lowUsers.map(row => row.x)),
    );
  }

}
