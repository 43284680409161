























































































































































































































































































































import { Vue } from 'vue-property-decorator';
import { Component, Mixins } from 'vue-mixin-decorator';
import moment from 'moment';

import { OverlayLoader, UserSearchModal } from '@/components/parts'
import { AchieveHorizontalBarChart, AchieveLineChart, AchievePieChart, AchieveStackBar, AchieveScatterChart, SkboxPlugins } from '@/components/chart'
import DepartmentAchieveIcons from '@/components/dashboard/DepartmentAchieveIcons.vue'
import { DashboardReport, WaitApproveUsers, AlertInfo, ApproveAlertInfo } from '@/types'
import { DashboardService } from '@/services'
import { Code, Messages } from '@/const'
import { DailyAchieveRatesResponseData } from "@/services/action_report_service";
import { DailyAbilityAchieveRatesPerActionResponse } from "@/services/dashboard_service";
import ActionListWaitApproveMessage from '@/components/parts/ActionListWaitApproveMessage.vue'

import { ServiceCallHelper, UserSearchHelper, UserSearchOptionsHelper } from '@/mixins'

import Filters from '@/utils/filters'

interface MixinInterface extends ServiceCallHelper, UserSearchHelper, UserSearchOptionsHelper {}

@Component({
  components: {
    OverlayLoader,
    AchieveLineChart,
    AchievePieChart,
    AchieveStackBar,
    AchieveScatterChart,
    AchieveHorizontalBarChart,
    DepartmentAchieveIcons,
    UserSearchModal,
    ActionListWaitApproveMessage,
    'vue-good-table': require('vue-good-table').VueGoodTable,
  },
  filters: Filters,
})
export default class DashboardContent extends Mixins<MixinInterface>(ServiceCallHelper, UserSearchHelper, UserSearchOptionsHelper) {

  /** 人数 */
  totalRows = 0;

  /** 機関集計テキスト */
  termText = "";

  /** 前回結果 */
  prevBusinessDay = "";

  /** API取得データ */
  report: DashboardReport;

  /** 達成率ラインチャート平均 */
  achieveLineChartAvg = 0;

  /** 円グラフ用プラグイン */
  pieChartPlugins = [new SkboxPlugins().drawDoughnutPercenteageText,];

  pageName = 'userTablePage';

  /** カテゴリのチャートデータ */
  abilityChartData = {}

  /** カテゴリのチャート高さ */
  abilityChartHeight = 0;

  /** アクションリスト承認待ちユーザー */
  waitApproveUsers: Array<WaitApproveUsers> = [];

  /**
   * mounted時、API呼び出し
   */
  mounted() {
    this.loadStats();
  }

  /** 絞り込み検索条件更新 */
  loadStats() {
    Vue.$log.debug('loadStats');
    Vue.$log.debug(this.searchParams);

    // 集計期間が設定されていない場合、デフォルトの期間を設定する
    if(!this.searchParams.termFrom && !this.searchParams.termTo) {
      this.searchParams.termTo = moment().subtract(1, "days").format('YYYY-MM-DD');
      this.searchParams.termFrom = moment(this.searchParams.termTo).subtract(3, 'months').format('YYYY-MM-DD');
    }

    this.showLoading();
    new DashboardService().getReport(this.searchParams).then((response) => { if(this.commonApiErrorHandler(response)) {
      const data = response.data;
      // SUCCESSの場合は値を設定
      if(data.code == Code.SUCCESS) {
        Vue.$log.debug(response);
        // null check
        if(!data.result.yesterdayRates.answerRate)
          data.result.yesterdayRates.answerRate = 0.0
        if(!data.result.yesterdayRates.achieveRate)
          data.result.yesterdayRates.achieveRate = 0.0
        this.report = data.result;
        this.totalRows = data.result.yesterdayRates.userCount;
        this.prevBusinessDay = data.result.prevBusinessDay;
        // 期間テキストの更新
        if(this.$refs.userSearchModal) {
          this.termText = (this.$refs.userSearchModal as UserSearchModal).termText();
        }
        // pie chartの明示的更新
        if(this.$refs.answerPieChart) {
          (this.$refs.answerPieChart as AchievePieChart).updateChart(data.result.yesterdayRates.answerRate * 100);
        }
        if(this.$refs.achievePieChart) {
          (this.$refs.achievePieChart as AchievePieChart).updateChart(data.result.yesterdayRates.achieveRate * 100);
        }
        if(this.$refs.reactionPieChart) {
          (this.$refs.reactionPieChart as AchievePieChart).updateChart(data.result.yesterdayRates.reactionRate * 100);
        }
        // 散布図の明示的更新
        if(this.$refs.achieveScatterChart) {
          Vue.$log.debug('update scatter chart');
          (this.$refs.achieveScatterChart as AchieveScatterChart).updateChart(data.result.yesterdayRates);
        }
        // ラインの明示的更新
        if(this.$refs.achieveLineChart) {
          Vue.$log.debug('achieveLineChart');
          Vue.$log.debug(this.searchParams);
          const achieveLineChart = this.$refs.achieveLineChart as AchieveLineChart;
          achieveLineChart.search(this.searchParams);
        }
        // カテゴリごとの達成率更新
        this.achieveHorizontalBarChartMounted();

        // 未承認アラートを管理
        const closedAlert = (this.$store.getters.closedAlert || []) as Array<AlertInfo | ApproveAlertInfo>;
        Vue.$log.debug('closedAlert');
        Vue.$log.debug(closedAlert);
        const alertWaitApproveUsers: any[] = [];
        data.result.waitApproveUsers.forEach(elem => {
          if(! closedAlert.find(alert => {
            return elem.userId == alert.userId && alert.alertType == "approveAlert" && (alert as  ApproveAlertInfo).requestDate == elem.requestDate
          })) {
            alertWaitApproveUsers.push(elem);
          }
        });
        this.waitApproveUsers = alertWaitApproveUsers;

        this.pageLoaded = true;
      }
      // その他のケースは接続エラーに
      else {
        this.$store.commit('setErrorMessage', Messages.NETWORK_ERROR);
      }
    } }).finally(() => {
      this.hideLoading();
      this.updateFilterText();
      this.updateFilterOptionsText();
    });

  }

  /** ユーザー検索モーダル更新時に期間テキストの更新 */
  userSearchMounted() {
    this.termText = (this.$refs.userSearchModal as UserSearchModal).termText();
  }

  /**
   * 達成率ラインチャートの描画
   */
  achieveLineChartMounted() {
    const achieveLineChart = this.$refs.achieveLineChart as AchieveLineChart;
    achieveLineChart.search(this.searchParams);
  }


  /**
   * 達成率ラインチャートの描画終了時、平均再描画実行
   */
  achieveLineChartUpdated(data: DailyAchieveRatesResponseData) {
    Vue.$log.debug('achieveLineChartUpdated');
    Vue.$log.debug(data);
    this.achieveLineChartAvg = data.chartDataAvg;
  }

  /**
   * カテゴリごとのチャート描画
   */
  achieveHorizontalBarChartMounted() {
    const renderChart = (response: DailyAbilityAchieveRatesPerActionResponse) => {
      if(this.commonApiErrorHandler(response)) {
        const data = response.data;
        // SUCCESSの場合は値を設定
        if(data.code == Code.SUCCESS) {
          // グラフの高さを動的に生成
          if (data.result.labels.length === 1) {
            this.abilityChartHeight = 80;
          } else if (data.result.labels.length === 2) {
            this.abilityChartHeight = 100;
          } else {
            this.abilityChartHeight = data.result.labels.length * 40;
          }
          this.abilityChartData = {
            labels: data.result.labels,
            data: data.result.data.map(v => Math.floor(v * 100)),
          }

          const achieveHorizontalBarChart = this.$refs.achieveHorizontalBarChart as AchieveHorizontalBarChart;
          achieveHorizontalBarChart.search(data.result);
        // その他のケースは接続エラーに
        } else {
          this.$store.commit('setErrorMessage', Messages.NETWORK_ERROR);
        }
      }
    }

    Vue.$log.debug("getAbilityAchieveRate");
    new DashboardService()
        .getAbilityAchieveRate(this.searchParams)
        .then(response => renderChart(response));
  }

  /**
   * 検索クリアボタン
   */
  dashboardClearSearchFilter() {
    this.clearSearchFilter();
    const userSearchModal = this.$refs.userSearchModal as UserSearchModal;
    userSearchModal.clearSearchOptionsFilter();
    if(this.$refs.achieveScatterChart) {
      (this.$refs.achieveScatterChart as AchieveScatterChart).updateChart(this.report.yesterdayRates);
    }
  }

  closeApproveAlert(requestDate: string, userId: number) {
    const deleteDate = moment().subtract(30, "days");
    const closedAlert = (this.$store.getters.closedAlert || []) as Array<AlertInfo | ApproveAlertInfo>;
    const newClosedAlert: Array<AlertInfo | ApproveAlertInfo> = [];
    const thisAlert = {requestDate: requestDate, userId: userId, alertType: "approveAlert"};
    closedAlert.forEach(alert => {
      if(moment((alert as ApproveAlertInfo).requestDate) > deleteDate) {
          newClosedAlert.push((alert as ApproveAlertInfo));
        }
      newClosedAlert.push((alert as AlertInfo));
    });
    newClosedAlert.push(thisAlert);
    this.$store.commit('setClosedAlert', [...new Set(newClosedAlert)]);
    Vue.$log.debug('this.$store.getters.closedAlert');
    Vue.$log.debug(this.$store.getters.closedAlert);

    // 未承認アラートの管理
    const waitApproveUsers: Array<any> = [];
    this.waitApproveUsers.forEach(elem => {
      if(requestDate != elem.requestDate || userId != elem.userId) {
        waitApproveUsers.push(elem);
      }
    })
    this.waitApproveUsers = waitApproveUsers;
  }
}
