import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import createMultiTabState from 'vuex-multi-tab-state';
import { UserInfo } from '@/types/user_info'
import { StampData, SearchPageInfo, ActionTeamboardCount, ActionReportSearchConditionsData, ActionReportSearchResultData } from '@/types'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    'userInfo': new UserInfo(),
    'favoriteStamps': new Array<StampData>(),
    'errorMessage': '',
    'infoMessage': '',
    'notifyMessage': '',
    'alRequireApproval': '',
    'forwardUrl': '',
    'searchParams': new SearchPageInfo(),
    'actionTeamboardCount': new ActionTeamboardCount(),
    'closedAlert': [],
    'isOpenSidemenu': false,
    'actionReportSearchConditions': new ActionReportSearchConditionsData(),
    'actionReportSearchResult': new ActionReportSearchResultData(),
    'isShowCommentList': "",
    "isShowHiddenComment": "1",
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    setErrorMessage(state, message) {
      state.errorMessage = message;
    }, 
    setInfoMessage(state, message) {
      state.infoMessage = message;
    }, 
    setNotifyMessage(state, notifyMessage) {
      state.notifyMessage = notifyMessage;
    }, 
    setAlRequireApproval(state, alRequireApproval) {
      state.alRequireApproval = alRequireApproval;
    }, 
    setEnableUserChecktest(state, enableUserChecktest) {
      state.userInfo.enableUserChecktest = enableUserChecktest;
    },
    setFavoriteStamps(state, favoriteStamps) {
      state.favoriteStamps = favoriteStamps;
    },
    setForwardUrl(state, forwardUrl) {
      state.forwardUrl = forwardUrl;
    },
    setSearchParams(state, searchParams) {
      state.searchParams = searchParams;
    },
    setActionTeamboardCount(state, actionTeamboardCount) {
      state.actionTeamboardCount = actionTeamboardCount;
    },
    setClosedAlert(state, closedAlert) {
      state.closedAlert = closedAlert;
    },
    setIsOpenSidemenu(state, isOpenSidemenu) {
      state.isOpenSidemenu = isOpenSidemenu;
    },
    setActionReportSearchConditions(state, conditions) {
      state.actionReportSearchConditions = conditions
    },
    setActionReportSearchResult(state, result) {
      state.actionReportSearchResult = result
    },
    setIsShowCommentList(state, isShow) {
      state.isShowCommentList = isShow
    },
    setIsShowHiddenComment(state, isShow) {
      state.isShowHiddenComment = isShow
    },
  },
  actions: {
  },
  modules: {
  },
  getters: {
    userInfo(state) {
      return state.userInfo;
    },
    userName(state) {
      return state.userInfo.userName;
    },
    companyName(state) {
      return state.userInfo.companyName;
    },
    userRole(state) {
      return state.userInfo.userRole;
    },
    errorMessage(state) {
      return state.errorMessage;
    },
    infoMessage(state) {
      return state.infoMessage;
    },
    notifyMessage(state) {
      return state.notifyMessage;
    },
    alRequireApproval(state) {
      return state.alRequireApproval;
    },
    isMember(state) {
      return state.userInfo.userRole == 3;
    },
    isMentor(state) {
      return state.userInfo.userRole == 2;
    },
    isSuper(state) {
      return state.userInfo.userRole == 1;
    },
    aboveManager(state) {
      return state.userInfo.userRole == 1 || state.userInfo.userRole == 2;
    },
    favoriteStamps(state) {
      return state.favoriteStamps;
    },
    forwardUrl(state) {
      return state.forwardUrl;
    },
    searchParams(state) {
      return state.searchParams;
    },
    actionTeamboardCount(state) {
      return state.actionTeamboardCount;
    },
    closedAlert(state) {
      return state.closedAlert;
    },
    isOpenSidemenu(state) {
      return state.isOpenSidemenu;
    },
    actionReportSearchConditions(state) {
      return state.actionReportSearchConditions;
    },
    actionReportSearchResult(state) {
      return state.actionReportSearchResult;
    },
    isShowCommentList(state) {
      console.log(state)
      return state.isShowCommentList;
    },
    isShowHiddenComment(state) {
      return state.isShowHiddenComment;
    },
  },
  plugins: [
    createPersistedState(),
    createMultiTabState(),
  ],
})
