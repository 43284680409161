import { AlertInfo, ApproveAlertInfo } from '@/types';

export class UserInfo {
  userName = '';
  companyName = '';
  userRole = -1;

  // チェックテスト完了時にリダイレクトするか(1:on, 0:off)
  ctRedirect = 0;

  // アクションリスト設定依頼を自動通知 
  ctActionNotify = 0;

  // 個人への社内平均点数(1:on, 0:off)
  showCompanyAvg = 0;

  // 管理者によるコメント機能(1:on, 0:off)
  showAdminComment = 0;

  // 管理者によるコメント返信機能(1:on, 0:off)
  showCommentReply = 0;

  // アクション設定時の追加ボタン
  showAddAction = 0;

  // メンバーへのアクションレポートの表示
  showMemberActionReportList = 0;

  // メンバーへのテストレポートの表示
  showMemberTestReportList = 0;

  // チェックテストの利用（会社設定）
  enableChecktest = 0;

  // チェックテストの利用（ユーザー設定）
  enableUserChecktest = 0;
  
  // アクションリストの利用
  enableActionList = 0;

  // カスタム
  enableCustomAction = 0;

  // SlackAPI利用可
  enableSlackApi = 0;

  // LineAPI利用可
  enableLineApi = 0;

  // LineWorksAPI利用可
  enableLineWorksApi = 0;

  // MEMO欄タイトル名
  memoTitle = '';

  // MEMO欄の補足説明
  memoDescription = '';

  // MEMO欄の内容例
  memoExample = '';

  // MEMO欄の初期表示
  memoInitialText = '';
  
  // 警告情報
  alert: AlertInfo | ApproveAlertInfo | undefined = undefined;

}
