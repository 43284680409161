import { DayOfWeek } from '@/types'

/**
 * ユーザー検索
 */
export class UserSearchData {
  userId = '';
  userName = '';
  newRecruit = '';
  hireYearFrom = '';
  hireYearTo = '';
  gender = '';
  departmentId = '';
  departmentIds: number[] = [];
  occupation = '';
  childOccupation = '';
  childOccupations: string[] = [];
  position = '';
  positions: string[] = [];
  termFrom = '';
  termTo = '';

  constructor(
        userId?: string,
        userName?: string,
        newRecruit?: string,
        hireYearFrom?: string,
        hireYearTo?: string,
        gender?: string,
        departmentId?: string,
        departmentIds?: number[],
        occupation?: string,
        childOccupation?: string,
        childOccupations?: string[],
        position?: string,
        positions?: string[],
        termFrom?: string,
        termTo?: string) {
    if(userId)
      this.userId = userId;
    if(userName)
      this.userName = userName;
    if(newRecruit)
      this.newRecruit = newRecruit;
    if(hireYearFrom)
      this.hireYearFrom = hireYearFrom;
    if(hireYearTo)
      this.hireYearTo = hireYearTo;
    if(gender)
      this.gender = gender;
    if(departmentId)
      this.departmentId = departmentId;
    if(departmentIds)
      this.departmentIds = departmentIds;
    if(occupation)
      this.occupation = occupation;
    if(childOccupation)
      this.childOccupation = childOccupation;
    if(childOccupations)
      this.childOccupations = childOccupations;
    if(position)
      this.position = position;
    if(positions)
      this.positions = positions;
    if(termFrom)
      this.termFrom = termFrom;
    if(termTo)
      this.termTo = termTo;
  }
}

/**
 * ユーザー登録
 */
export class UserAddData {
  userName = '';
  userAccount = '';
  notifyAddress = '';
  departmentId = '';
  userRole = '';
  usePersonalSetting = '';
  alCycleFreq = '';
  alDayOfWeeks = '';
  alDayOfWeeksObj = new DayOfWeek();
  alMonthday = '';
  arCycleFreq = '';
  alSendTime = '';
  arDayOfWeeks = '';
  arDayOfWeeksObj = new DayOfWeek();
  arMonthday = '';
  arSendTime = '';
  birthday = '';
  gender = '';
  newRecruit = '';
  hireYear = '';
  hireMonth = '';
  occupation = '';
  childOccupation = '';
  position = '';
}

/**
 * ユーザー更新
 */
export class UserEditData {
  userName = '';
  userAccount = '';
  notifyAddress = '';
  notifySlackId = '';
  notifyLineId = '';
  notifyLineWorksId = '';
  loginId = '';
  loginPassword = '';
  loginPassword2 = '';
  departmentId = '';
  userRole = '';
  usePersonalSetting = '';
  alCycleFreq = '';
  alDayOfWeeks = '';
  alDayOfWeeksObj = new DayOfWeek();
  alMonthday = '';
  alSendTime = '';
  arCycleFreq = '';
  arDayOfWeeks = '';
  arDayOfWeeksObj = new DayOfWeek();
  arMonthday = '';
  arSendTime = '';
  birthday = '';
  gender = '';
  newRecruit = '';
  hireYear = '';
  hireMonth = '';
  occupation = '';
  childOccupation = '';
  position = '';
  alRequireApproval = '';
  profileImage = '';
  hasAction = 0;
  hasNotifyLineId = 0;
  isSuspend = 0;
  followUserAnswerNotify = 0;
  id = 0;
}

/**
 * 更新時の読み取り専用フィールドの情報
 */
export class UserEditReadOnly {
  userName = true; 
  departmentId = true;
  userRole = true;
  alCycleFreq = true;
  alWeekdays = true;
  alMonthday = true;
  arCycleFreq = true;
  arWeekdays = true;
  arMonthday = true;

  setMentor() {
    this.userName = true; 
    this.departmentId = false;
    this.userRole = false;
    this.alCycleFreq = false;
    this.alWeekdays = false;
    this.alMonthday = false;
    this.arCycleFreq = false;
    this.arWeekdays = false;
    this.arMonthday = false;
  }

  setSuper() {
    this.userName = false; 
    this.departmentId = false;
    this.userRole = false;
    this.alCycleFreq = false;
    this.alWeekdays = false;
    this.alMonthday = false;
    this.arCycleFreq = false;
    this.arWeekdays = false;
    this.arMonthday = false;
  }
}

export interface UserProfileImageData {
  profileImage: string;
}