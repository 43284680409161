





























































































































































































































































































































































































import { Prop, Watch, Vue } from "vue-property-decorator";
import { Component, Mixins } from "vue-mixin-decorator";
import "vue-good-table/dist/vue-good-table.css";
import { default as ActionAnswerService } from "@/services/action_answer_service";
import StampService from "@/services/stamp_service";
import {
  ActionTimelineData,
  CommentData,
  DateSearchData,
  StampData,
  PostStampData,
} from "@/types";
import moment from 'moment'

import Filters from '@/utils/filters'

import { Code } from "@/const";

import GoodTableHeader from "@/mixins/good_table_mixin";
import ActionReportServiceHelper from "@/mixins/action_report_service_helper";

import { OverlayLoader, DateSearchModal } from "@/components/parts";
import CommentUpdateModal from "@/components/action_report/CommentUpdateModal.vue";
import CommentListModal from "@/components/action_report/CommentListModal.vue";
import CommentText from "@/components/action_report/CommentText.vue";
import StampModal from "@/components/action_report/StampModal.vue";
import StampButton from "@/components/action_report/StampButton.vue";
import { AchievePieChart, SkboxPlugins } from "@/components/chart";

interface MixinInterface
  extends ActionReportServiceHelper,
    GoodTableHeader<ActionTimelineData> {}

import "@/styles/tooltips.scss";

@Component({
  components: {
    OverlayLoader,
    CommentUpdateModal,
    CommentListModal,
    StampModal,
    StampButton,
    CommentText,
    AchievePieChart,
    DateSearchModal,
  },
  filters: Filters
})
export default class TeamBoardDetails extends Mixins<MixinInterface>(
  ActionReportServiceHelper,
  GoodTableHeader
) {
  /** ユーザーID */
  userId = "";

  /** row data */
  rows: any[] = [];

  /** Action IDに対して紐づく説明テキスト */
  actionTexts: any = {};

  /** userIdに紐づくユーザーの各種情報 */
  userInfo: any = {};

  /** コメントモーダルのモード */
  commentMode = "add";

  /** コメントモーダルの対象日付 */
  commentDate = "";

  /** コメントモーダルの初期表示テキスト */
  commentText = "";

  memoComment: { memo: string; date: string } = { memo: "", date: "" };

  /** アクション回答日時 */
  answerTime = "";

  /** ナイショ話を閲覧できるかの判定 */
  hasHiddenComment = false;

  /** 検索条件テキスト */
  filterText = '';

  /** 入力フィールド */
  fields = new DateSearchData(
      moment().subtract(1, 'month').format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD'));

  /** 選択Row index */
  selectedRowIdx = -1;

  /** 同日付のテームボード index */
  selectedInsideRowIdx = -1;

  /** 選択回答日 */
  selectAnswerDate = "";

  /** コメント一覧表示用データ */
  listComments: Array<CommentData> = []; /** 円グラフ用プラグイン */

  /** 閲覧ユーザーのコメントが存在するかの判定 */
  inMyComment = false;

  pieChartPlugins = [new SkboxPlugins().drawDoughnutPercenteageText];

  selectedUserId = 0;

  dailyAnswerId = "";

  /** stampデータのclass出し分け */
  stampClass(stamp: StampData) {
    if (stamp.myStamp) {
      return "btn  actionreport--stamp actionreport--stamp-active";
    }
    return "btn  actionreport--stamp";
  }

  mounted() {
    this.loadItems();
  }

  /**
   * 一覧の取得
   */
  loadItems(isCommentList = false, targetDate = "", targetUserId = 0) {
    this.isLoading = true;

    // 日付検索モーダルから値の取得
    const dateSearchModal = this.$refs.dateSearchModal as DateSearchModal;
    if(dateSearchModal) {
      this.filterText = dateSearchModal.searchText();
      // 子コンポーネントで参照されている値の設定
      this.fields = new DateSearchData(
        dateSearchModal.fields.dateFrom,
        dateSearchModal.fields.dateTo
      );
      this.updateParams({filters: {
        'termFrom': this.fields.dateFrom,
        'termTo': this.fields.dateTo
      }});
    }

    Vue.$log.debug(this.serverParams);

    // 指定userIdのレポート取得
    new ActionAnswerService()
      .searchActionTeamBoard(this.serverParams)
      .then((response) => {
        if (this.commonApiErrorHandler(response)) {
          const data = response.data;
          Vue.$log.debug(this.userId);
          if (this.commonApiErrorHandler(response)) {
            // SUCCESSの場合は値を設定
            if (data.code == Code.SUCCESS) {
              this.totalRows = response.data.result.totalRows;
              if (this.totalRows > 0) {
                // 同じ日付は同配列にまとめる
                const row: { [key: string]: any[] } = {};
                data.result.action.map((v: ActionTimelineData) => {
                  if (!row[v.answerDate]) {
                    row[v.answerDate] = [];
                  }
                  row[v.answerDate]?.push(v);
                });
                this.rows = Object.values(row);

                // コメント一覧からの投稿の場合はコメントを更新
                if (isCommentList) {
                  const currentAction = data.result.action.filter(
                    (v) =>
                      v.answerDate === targetDate && v.userId === targetUserId
                  );
                  this.listComments = currentAction[0].comments;
                }

                this.actionTexts = data.result.actionTexts;
                this.userInfo = data.result.users;
                Vue.$log.debug(this.userInfo);
                this.$emit(
                  "teamBoardLoaded",
                  data.result.action,
                  data.result.users,
                  data.result.waitApproveUsers,
                  this.fields
                );
              } else {
                this.rows = [];
                Vue.$log.debug(
                  `data.result.waitApproveUsers=${data.result.waitApproveUsers}`
                );
                this.$emit(
                  "teamBoardLoaded",
                  data.result.action,
                  data.result.users,
                  data.result.waitApproveUsers,
                  this.fields
                );
              }
              this.pageLoaded = true;
            }
          }
        }
      });
  }

  /**
   * スタンプ選択モーダル表示
   */
  clickShowStampModal(
    date: string,
    rowIdx: number,
    insideRowIndex: number,
    userId: string,
    dailyAnswerId: string
  ) {
    this.commentDate = date;
    this.selectedRowIdx = rowIdx;
    this.selectedInsideRowIdx = insideRowIndex;
    this.userId = userId;
    this.dailyAnswerId = dailyAnswerId;
    this.$modal.show("stamp-modal");
  }

  /**
   * モーダルのスタンプクリック
   */
  clickModalStamp(stamp: StampData) {
    Vue.$log.debug(stamp.stampId);
    // stamp登録
    const stampData = {
      dailyAnswerId: this.dailyAnswerId,
      answerDate: this.commentDate,
      stampId: stamp.stampId,
      userId: this.userId,
    };
    Vue.$log.debug("stampData");
    Vue.$log.debug(stampData);
    this.$emit("close");
    new StampService()
      .postStamp(stampData)
      .then((response) => {
        if (this.commonApiErrorHandler(response)) {
          Vue.$log.debug(response);
          this.refreshStampDisp(
            this.selectedRowIdx,
            stampData,
            this.selectedInsideRowIdx
          );
          // チームボードカウント更新
          this.updateTeamboardCount();
        }
      })
      .finally(() => {
        this.hideLoading();
      });
  }

  /**
   * 表示スタンプクリック
   */
  clickStampImage(
    stamp: StampData,
    answerDate: string,
    rowIdx: number,
    insideRowIndex: number,
    userId: string,
    dailyAnswerId: string
  ) {
    if (!userId) {
      return;
    }
    Vue.$log.debug(stamp);
    // API用パラメータ
    const postData = {
      answerDate: answerDate,
      stampId: stamp.stampId,
      userId: userId,
      dailyAnswerId: dailyAnswerId,
    } as PostStampData;
    Vue.$log.debug(rowIdx);

    // 自スタンプがある場合は削除
    if (stamp.myStamp) {
      new StampService().deleteStamp(postData).then((response) => {
        if (this.commonApiErrorHandler(response)) {
          Vue.$log.debug("deleteStamp");
          this.refreshStampDisp(rowIdx, postData, insideRowIndex);
          // チームボードカウント更新
          this.updateTeamboardCount();
        }
      });
    }
    // 自スタンプがない場合は追加
    else {
      new StampService().postStamp(postData).then((response) => {
        if (this.commonApiErrorHandler(response)) {
          this.refreshStampDisp(rowIdx, postData, insideRowIndex);
          // チームボードカウント更新
          this.updateTeamboardCount();
        }
      });
    }
  }

  /**
   * コメントする / コメントを編集するボタン
   */
  clickCommentUpdateButton(
    date: string,
    comments: Array<CommentData>,
    userId: string
  ) {
    Vue.$log.debug(date);
    this.commentMode = "add";
    this.commentText = "";
    this.commentDate = date;
    this.userId = userId;
    for (const comment of comments) {
      if (comment.myComment == 1) {
        this.commentMode = "update";
        this.commentText = comment.text;
        break;
      }
    }
    this.$modal.show("comment-update-modal");
  }

  /** コメント投稿成功通知 */
  commentUpdateSuccess() {
    this.$modal.hide("comment-update-modal");
    // アイテム更新
    this.loadItems();
    // チームボードカウント更新
    this.updateTeamboardCount();
  }

  /** コメント投稿成功通知 */
  commentSendSuccess(data: {date: string; userId: number; replyUserId: number}) {
    // アイテム更新
    this.loadItems(true, data.date, data.userId);
    if(!data.replyUserId) {
      this.inMyComment = true;
    }
    // チームボードカウント更新
    this.updateTeamboardCount();
  }

   /** コメント削除成功通知 */
  commentDeleteSuccess(data: {date: string; userId: number; replyUserId: number}) {
    this.loadItems(true, data.date, data.userId);
    if(!data.replyUserId) {
      this.inMyComment = false;
    }
    // チームボードカウント更新
    this.updateTeamboardCount();
  }

  /**
   * コメントボタン（コメント一覧表示）
   */
  clickCommentList(
    dailyAnswerId: string,
    userId: string,
    answerDate: string,
    answerTime: string,
    comments: Array<CommentData>,
    memo: string,
    hiddenComment: string,
  ) {
    const commentListModal = this.$refs.commentListModal as CommentListModal;
    commentListModal.clearReplyUser();
    if(commentListModal.dailyAnswerId != dailyAnswerId) {
      commentListModal.clearInputComment();
      commentListModal.clearReplyInputComment();
    }
    if (comments && comments.length >= 0) {
      this.memoComment = { memo: memo, date: answerDate };
      this.$modal.show("comment-list-modal");
      this.userId = userId;
      this.listComments = comments;
      this.selectAnswerDate = answerDate;
      this.answerTime = answerTime;
      if(hiddenComment) {
        this.hasHiddenComment = true;
      } else {
        this.hasHiddenComment = false;
      }
      const myComment = comments.filter(e => e.myComment == 1);
      if(myComment.length > 0) {
        this.inMyComment = true;
      } else {
        this.inMyComment = false;
      }
      commentListModal.refreshStampDisp(dailyAnswerId, this.userId, answerDate);
    }
  }

  /** コメント一覧close通知 */
  closeCommentList() {
    this.$modal.hide("comment-list-modal");
  }

  /** スタンプ表示情報の更新 */
  refreshStampDisp(
    rowIdx: number,
    stampData: PostStampData,
    insideRowIndex: number
  ) {
    new StampService()
      .getDailyStamp(stampData.userId, stampData.answerDate)
      .then((response) => {
        if (this.commonApiErrorHandler(response)) {
          Vue.$log.debug("refreshStampDisp");
          Vue.$log.debug(response.data.result.stamp);
          this.rows[rowIdx][insideRowIndex].stamps = response.data.result.stamp;
        }
      });
  }

  /**
   * 前日からの流動率を取得
   */
  getRatio(current: number, prev: number) {
    let symbol = "";
    if (current > prev) {
      symbol = "+";
    } else if (current < prev) {
      symbol = "-";
    }

    const num = Math.floor(Math.abs(current * 100 - prev * 100));

    return `${symbol}${num}%`;
  }

  /**
   * グラフ横の顔画像を取得
   */
  getFaceImage(achiveRate: number) {
    if (achiveRate >= 90) {
      return `${process.env.VUE_APP_IMAGE_BASE_URL}/chart/point_high_400.png`;
    } else if (achiveRate > 40 && achiveRate <= 90) {
      return `${process.env.VUE_APP_IMAGE_BASE_URL}/chart/point_middle_400.png`;
    } else {
      return `${process.env.VUE_APP_IMAGE_BASE_URL}/chart/point_low_400.png`;
    }
  }

  /**
   * グラフ横の顔画像のタイトル文字列を取得
   */
  getFaceTitle(achiveRate: number) {
    if (achiveRate >= 90) {
      return "上位・・・達成率90%〜100%";
    } else if (achiveRate > 40 && achiveRate <= 90) {
      return "中位・・・達成率40%〜90%未満";
    } else {
      return "下位・・・達成率0%〜40%未満";
    }
  }

  /**
   * コメントの編集するテキストとidを代入
   */
  commentUpdate(text: string, commentId: number, replyUserId = 0) {
    this.loadItems();
    commentData: for (const comment of this.listComments) {
      if(replyUserId && comment.replyComment) {
        for (const replyComment of comment.replyComment) {
          if (replyComment.commentId == commentId) {
            replyComment.text = text;
            replyComment.commentId = commentId;
            break commentData;
          }
        }
      } else if(comment.commentId == commentId) {
        comment.text = text;
        comment.commentId = commentId;
        break;
      }
    }
  }

  /** リンクをクリップボードにコピー */
  copyActionLink(aid: number, userId: number) {
    const text = `https://${window.location.host}/action_report/${userId}?aid=${aid}`;
    navigator.clipboard.writeText(text);
  }

  /** 日付検索反映 */
  closeDateSearchModal() {
    // 日付検索モーダルから値の取得
    Vue.$log.debug("closeDateSearchModal");
    this.loadItems();
    this.$modal.hide("date-search-modal");
  }

  /** 条件を絞り込み検索ボタン */
  clickFilterButton() {
    this.$modal.show("date-search-modal");
  }
}
