import { Mixins } from "vue-mixin-decorator";
import { SkillBoxResponse, AxiosResponse } from '@/types/axios_types';
import { StampData, PostStampData, PostCommentStampData } from '@/types';

import RequestMixin from '@/mixins/request_mixin'; 

/** スタンプ表示一覧 */
export interface StampListData {
  stamps: Array<StampData>;
  customStamps: Array<StampData>;
}
export class StampListSkillboxResponse extends SkillBoxResponse<StampListData>{}
export class StampListResponse extends AxiosResponse<StampListSkillboxResponse>{}

/** 日毎スタンプ一覧 */
export interface DailyStampData {
  stamp: StampData[];
}
export class DailyStampSkillboxResponse extends SkillBoxResponse<DailyStampData>{}
export class DailyStampResponse extends AxiosResponse<DailyStampSkillboxResponse>{}

/**
 * スタンプ投稿service
 */
export default class StampService extends Mixins<RequestMixin>(RequestMixin) {

  /**
   * スタンプ一覧取得
   */
  public getStampList(): Promise<StampListResponse> {
    return this.getReq(`/api/v1/stamp`, {}) as Promise<StampListResponse>;
  }

  /**
   * スタンプファイルアップロード
   *
   * @param formData : name=uploadFileにinput fileを持つForm
   */
  public uploadFile(formData: FormData): Promise<AxiosResponse> {
    return this.uploadReq('/api/v1/stamp', formData);
  }

  /**
   * 当該日/当該ユーザーのスタンプ取得
   */
  public getDailyStamp(userId: string, answerDate: string): Promise<DailyStampResponse> {
    const param = {'userId': userId, 'answerDate': answerDate};
    return this.getReq(`/api/v1/action_stamp`, param) as Promise<AxiosResponse>;
  }

  /**
   * スタンプ投稿
   */
  public postStamp(stamp: PostStampData): Promise<AxiosResponse> {
    const param = {
      "stamp": {
        "dailyAnswerId": stamp.dailyAnswerId,
        "stampId": stamp.stampId,
      }
    }
    return this.postReq(`/api/v1/action_stamp`, param) as Promise<AxiosResponse>;
  }

  /**
   * スタンプ取消
   */
  public deleteStamp(stamp: PostStampData): Promise<AxiosResponse> {
    const param = {
      "dailyAnswerId": stamp.dailyAnswerId,
      "stampId": stamp.stampId,
    }
    return this.deleteReq(`/api/v1/action_stamp`, param) as Promise<AxiosResponse>;
  }

  /**
   * コメントスタンプ取得
   */
  public getCommentStamp(dailyAnswerId: string) {
    return this.getReq(`/api/v1/action_comment_stamp`, {'dailyAnswerId': dailyAnswerId}) as Promise<any>;
  }

  /**
   * コメントスタンプ投稿
   */
  public postCommentStamp(stamp: PostCommentStampData): Promise<AxiosResponse> {
    const param = {
      "stamp": {
        "commentId": stamp.commentId,
        "stampId": stamp.stampId,
      }
    }
    return this.postReq(`/api/v1/action_comment_stamp`, param) as Promise<AxiosResponse>;
  }

  /**
   * コメントスタンプ取消
   */
  public deleteCommentStamp(stamp: PostCommentStampData): Promise<AxiosResponse> {
    const param = {
      "commentId": stamp.commentId,
      "stampId": stamp.stampId,
    }
    return this.deleteReq(`/api/v1/action_comment_stamp`, param) as Promise<AxiosResponse>;
  }
}


