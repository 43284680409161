import { Mixins } from "vue-mixin-decorator"
import { AxiosResponse, SkillBoxResponse} from '@/types/axios_types'
import { ActionReportListData, UserSearchData, DateValueData, AvgDateValueData } from '@/types'
import { SearchParams } from '@/types/good_table_types'


import RequestMixin from '@/mixins/request_mixin'

/** アクションレポート表示一覧 */
interface ActionReportListResponseData {
  userName: string;
  recordCount: number;
  report: Array<ActionReportListData>;
}
export class ActionReportListSkillBoxResponse extends SkillBoxResponse<ActionReportListResponseData>{}
export class ActionReportListResponse extends AxiosResponse<ActionReportListSkillBoxResponse>{}

/** 日毎達成率推移チャートデータAPI */
export interface DailyAchieveRatesResponseData {
  chartData: Array<DateValueData>;
  chartDataAvg: number;
}

/** 日毎達成率推移チャートデータAPI(Action別) */
export interface DailyAchieveRatesPerActionResponseData {
  chartData: Array<AvgDateValueData>;
  chartDataAvg: number;
  date: Array<string>;
}

export interface DailyAbilityRatesAbilityBar {
  data: Array<number>;
  labels: Array<string>;
}

export interface DailyAbilityRatesAbilitySerieChartData {
  abilityName: string;
  value: Array<number>;
}

export interface DailyAbilityRatesAbilitySeries {
  chartData: Array<DailyAbilityRatesAbilitySerieChartData>;
  chartDataAvg: number;
  date: Array<string>;
  latestChangeDate: string;
}

export interface DailyAbilityRatesTotal {
  achieveRate: number;
  answerRate: number;
  reactionRate: number;
  validAnswerRate: number;
  userCount: number;
}

/** カテゴリごとの達成率チャートデータAPI */
export interface DailyAbilityRatesResponseData {
  abilityBar: DailyAbilityRatesAbilityBar;
  abilitySeries: DailyAbilityRatesAbilitySeries;
  latestChangeDate: string;
  total: DailyAbilityRatesTotal;
}

export class DailyAchieveRatesSkillBoxResponse extends SkillBoxResponse<DailyAchieveRatesResponseData>{}
export class DailyAchieveRatesResponse extends AxiosResponse<DailyAchieveRatesSkillBoxResponse>{}

export class DailyAchieveRatesPerActionSkillBoxResponse extends SkillBoxResponse<DailyAchieveRatesPerActionResponseData>{}
export class DailyAchieveRatesPerActionResponse extends AxiosResponse<DailyAchieveRatesPerActionSkillBoxResponse>{}

export class DailyAbilityRatesSkillBoxResponse extends SkillBoxResponse<DailyAbilityRatesResponseData>{}
export class DailyAbilityRatesResponse extends AxiosResponse<DailyAbilityRatesSkillBoxResponse>{}

/**
 * アクションレポートservice
 */
export default class ActionReportService extends Mixins<RequestMixin>(RequestMixin) {

  /**
   * アクションレポート一覧検索
   */
  public getReportList(params: SearchParams): Promise<ActionReportListResponse> {
    return this.getReq(`/api/v1/action_report`, params) as Promise<ActionReportListResponse>;
  }

  /**
   * 日毎達成率推移チャートデータAPI
   */
  public getDailyAchieveRate(params: UserSearchData): Promise<DailyAchieveRatesResponse> {
    return this.getReq(`/api/v1/action_achieve_rate`, {'filters': params}) as Promise<DailyAchieveRatesResponse>;
  }

  /**
   * 日毎達成率推移チャートデータAPI（アクションごと）
   */
  public getDailyAchieveRatePerAction(params: UserSearchData): Promise<DailyAchieveRatesPerActionResponse> {
    return this.getReq(`/api/v1/action_achieve_rate`, {'filters': params, 't': 'a'}) as Promise<DailyAchieveRatesPerActionResponse>;
  }

  /**
   * 日毎達成率推移チャートデータAPI（自身のみ、アクションごと）
   */
  public getMyDailyAchieveRate(params: UserSearchData): Promise<DailyAchieveRatesPerActionResponse> {
    return this.getReq(`/api/v1/my_action_achieve_rate`, {'filters': params, 't': 'a'}) as Promise<DailyAchieveRatesPerActionResponse>;
  }
  
  /**
   * 全ユーザーアクション設定依頼
   */
  // eslint-disable-next-line
  public requestActionSettingsAll(param: SearchParams): Promise<AxiosResponse> {
    return this.postReq('/api/v1/request_action_answer', param) as Promise<AxiosResponse>;
  }

  /**
   * 指定ユーザーアクション設定依頼
   */
  public requestActionSettings(userIds: Array<number>): Promise<AxiosResponse> {
    const params = {'request': {'userIds': userIds}}
    return this.postReq('/api/v1/request_action_answer', params) as Promise<AxiosResponse>;
  }

  /**
   * 全ユーザーアクション回答依頼
   */
  // eslint-disable-next-line
  public requestActionAnswerAll(param: SearchParams): Promise<AxiosResponse> {
    return this.postReq('/api/v1/request_action_answer', param) as Promise<AxiosResponse>;
  }

  /**
   * 指定ユーザーアクション回答依頼
   */
  public requestActionAnswer(userIds: Array<number>): Promise<AxiosResponse> {
    const params = {'request': {'userIds': userIds}}
    return this.postReq('/api/v1/request_action_answer', params) as Promise<AxiosResponse>;
  }

  /**
   * マイアクションレポートのカテゴリごとの達成率等を取得
   */
  public getMyAbilityAchiveRate(termFrom: string, termTo: string): Promise<DailyAbilityRatesResponse> {
    const params = {filters: {
      termFrom: termFrom,
      termTo: termTo,
    }}
    return this.getReq(`/api/v1/action_report_detail?tot=1&abl=1&abt=1`, params) as Promise<DailyAbilityRatesResponse>;
  }

  /**
   * 他ユーザアクションレポートのカテゴリごとの達成率等を取得
   */
  public getAbilityAchiveRate(userId: string, termFrom: string, termTo: string): Promise<DailyAbilityRatesResponse> {
    const params = {filters: {
      termFrom: termFrom,
      termTo: termTo,
    }}
    return this.getReq(`/api/v1/action_report_detail/${userId}?tot=1&abl=1&abt=1`, params) as Promise<DailyAbilityRatesResponse>;
  }
}

